<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config.json";

import axios from "axios";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
//import _ from "lodash";
import vue2Dropzone from "vue2-dropzone";
import {msgModalOptCenter} from "@/components/globalFunctions";
import draggable from "vuedraggable";


export default {
  name: 'VerticalImageManager',
  page: {
    title: "이벤트 이미지 파일 등록",
    meta: [{name: "description", content: appConfig.description}],
  },
  //전단행사목록페이지에서 페이지이동해 올 때 가져온 해당 행사 기본정보
  props: {
    leaflet_name: {
      type: String,
      default: ''
    },
    leaflet_srl: {
      type: String,
      default: ''
    },
    event_s_date: {
      type: String,
      default: ''
    },
    event_e_date: {
      type: String,
      default: ''
    }
  },

  components: {
    Layout,
    vueDropzone: vue2Dropzone,
    draggable
  },

  data() {
    return {
      //projectGrid: projectGrid,
      title: this.leaflet_name,
      period: '(행사기간: ' + this.event_s_date + ' - ' + this.event_e_date + ')',  //해당행사 기간
      items: '전단이미지를 등록/변경 및 순서를 정할 수 있습니다.',
      btnName: '',
      filterCategory: "all",
      visible: false,
      index: 0, // default: 0
      imageList: [],                                                              //해당행사 전단지 이미지 목록
      dropzoneOptions: {                                                          //이미지를 끌어오거나 클릭해서 등록시키는 영역 기본 option값
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        headers: {"My-Awesome-Header": "header value"},
        acceptedFiles: "image/jpeg, image/jpg, image/png"
      },
    };
  },

  computed: {
    /*orderedImage: function () {
      console.log(_.orderBy(this.imageList, ['seq'], ['asc']))
      return _.orderBy(this.imageList, ['seq'], ['asc'])
    },*/

    ...mapState(loggedInfoObject)
  },

  mounted() {
    this.callFlyersImgList();
  },

  methods: {
    //전단지 이미지 목록 불러오기
    callFlyersImgList() {
      axios.get(`/api/web/market/v/leaflet/img/${this.logged_info.mart_srl}/${this.leaflet_srl}`)
          .then((response) => {
            console.log(response.data)
            this.imageList = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },

    //전단지 이미지 순서보여주기
    placeholderFunc(val) {
      val++
      let tmpStr = String(val)
      return tmpStr
    },

    //전단지 이미지 url encoding
    urlEncoding(url) {
      let encodeUrl = encodeURI(url)
      //console.log(url)
      return encodeUrl
    },

    //전단지 이미지 순서변경
    seqModify(imgSrl, before, after) {
      let strImgSrl = String(imgSrl)
      let strBefore = String(before)
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)
      console.log(after)
      if (after > this.imageList.length) {
        this.$bvModal.msgBoxOk('이미지 갯수 내에서 순서를 정해주세요.', msgModalOptCenter)
        return
      }
      if (after === "0") {
        this.$bvModal.msgBoxOk('0을 제외한 숫자로 정해주세요.', msgModalOptCenter)
        return
      }

      axios.put(`/api/web/market/v/leaflet/img/seq/v2/${this.leaflet_srl}`,
          {
            leaflet_img_srl: strImgSrl,
            before_seq: strBefore,
            after_seq: after,
            leaflet_info_srl: this.leaflet_srl
          })
          .then(() => {
            //console.log(response.data)
            //this.initInput(pSrl)
            this.callFlyersImgList()
          })
          .catch(error => {
            console.log(error)
          })
    },

    //전단지 이미지 삭제
    deleteTheImage(leaflet_img_srl) {
      axios.delete(`/api/web/market/v/leaflet/img/${this.logged_info.mart_srl}/${this.leaflet_srl}`, {
        data: {
          leaflet_img_srl: leaflet_img_srl,
          market_srl: this.logged_info.mart_srl,
          leaflet_info_srl: this.leaflet_srl
        }
      }).then(() => {
        //console.log(response.data)
        this.callFlyersImgList()
      }).catch(error => {
        console.log(error)
      })
    },

    //순서를 변경할 이미지의 변경 후의 순서를 지정
    findId(imgSrl) {
      console.log(`${imgSrl}`)
      let tmp = this.$refs[`${imgSrl}`][0].value
      console.log('tmp', tmp)
      return tmp
    },

    //dropzone이미지파일 등록하기
    uploadImageFileDZ(event) {
      console.log(event)
      console.log(event.type)

      if (event.type !== "image/jpeg" && event.type != "image/png") {
        this.$bvModal.msgBoxOk("허용된 이미지 형식이 아닙니다. jpg 또는 png 형식의 이미지를 올려주세요.", msgModalOptCenter)
        return
      }
      const fd = new FormData()
      fd.append('img', event)
      fd.append('market_srl', this.logged_info.mart_srl)
      fd.append('leaflet_info_srl', this.leaflet_srl)
      axios.post(`/api/web/market/v/leaflet/img/${this.logged_info.mart_srl}/${this.leaflet_srl}`, fd).then((response) => {
        console.log(response.data)
        //this.initInput(pSrl)
        this.callFlyersImgList()
      }).catch(error => {
        console.log(error)
      })
    },

    //순서변경
    dragEnd(evt) {
      console.log(evt.newIndex + 1);
      this.seqModify(
          this.imageList[evt.newIndex].leaflet_img_srl,
          evt.oldIndex + 1,
          evt.newIndex + 1
      );
    },
  },

};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :period="period" :items="items"/> -->
    <!-- start dropzone 모아서 올리는 형태가 아니라 파일을 하나씩 올리는대로 즉시 업로드 되도록 수정됨 -->
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h3 class="card-title">이벤트 이미지파일 등록</h3>
      </div>
      <div class="card-body">
        <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-file-added="uploadImageFileDZ"
        >
          <div class="dropzone-custom-content">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
            <h4>이미지 파일을 올리시거나 클릭해서 이미지 파일을 선택해주세요.</h4>
          </div>
        </vue-dropzone>

      </div>
    </div>
    <!-- end dropzone -->


    <!-- start 전단이미지가 보이는 영역-->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="row gallery-wrapper">

                  <draggable
                      id="ingProducts"
                      class="row gallery-wrapper"
                      :list="this.imageList"
                      @end="dragEnd"
                  >

                    <!-- 전단이미지 -->
                    <div
                        v-for="(row, index) in imageList"
                        :key=row.leaflet_img_srl
                        class="col-xl-4 col-sm-6"
                    >
                      <div class="gallery-box card">
                        <!-- 전단이미지 이미지영역 -->
                        <div class="gallery-container">
                          <img
                              :src="`${urlEncoding(row.img_path)}`"
                              style=" margin: auto; max-width: 100%; height: 400px; top: 0; bottom: 0;"
                          />
                        </div>
                        <!-- 전단이미지 아래 줄 -->
                        <!--                      <hr style="width: 100%; color: #adb5bd; margin: 8px 0;">-->
                        <!-- 전단이미지 아래 순서변경 및 삭제버튼 영역 -->
                        <div class="gallery-bottom-button"
                             style="height: 38px; margin: 8px; display: flex; flex-direction: row; justify-content: center; align-items: center; box-sizing: border-box">

                          <span class="input-group-addon" style="font-size: 14px; border: 0; margin: 0 4px;">순서를</span>
                          <input :placeholder="placeholderFunc(index)" type="number"
                                 :ref="`ordering${row.leaflet_img_srl}`"
                                 onkeyup="this.value = this.value.replace(/[^0-9]/g,'')"
                                 style="width: 48px; height: 100%">
                          <span class="input-group-addon" style="font-size: 14px; border: 0; margin: 0 4px;">번째로</span>
                          <span>
                                <b-button
                                    @click="seqModify(row.leaflet_img_srl, row.seq, findId(`ordering${row.leaflet_img_srl}`))"
                                    block variant="outline-secondary"
                                    style="margin-right: 10px">
                                  변경
                                </b-button>
                              </span>

                          <span>
                                <b-button block variant="outline-danger"
                                          style=""
                                          @click="deleteTheImage(row.leaflet_img_srl)">
                                  삭제
                                </b-button>
                              </span>
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <!-- End 전단이미지 -->
                </div>
                <!-- end row -->
                <vue-easy-lightbox
                    escDisabled
                    moveDisabled
                    :visible="visible"
                    :index="index"
                    :imgs="imageList"
                    @hide="visible = false"
                ></vue-easy-lightbox>
              </div>
            </div>
            <!-- end row -->
          </div>
          <!-- ene card body -->
        </div>
        <!-- end card -->

      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
